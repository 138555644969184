import React from 'react';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  value: {
    color: 'white',
  },
  card: {
    width: 120,
    height: 120,
  },
});

const Temperature = ({ value }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div>
        <Typography variant='h6'>
            {translate('res.gateways.fields.humidity')}
          </Typography>

          <Paper
            className={classes.card}
            style={{ backgroundColor: '#a2c7ff' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='row'
              height='100%'
              alignItems='center'
              alignContent='center'
              justifyContent='center'
            >
              <Typography variant='h3' className={classes.value}>
                {value}
                <span style={{ fontSize: '.4em' }}>
                 %
                </span>
              </Typography>
            </Box>
          </Paper>
    </div>
  );
};

export default Temperature;
