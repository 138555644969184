import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';

import Welcome from './Welcome';
import Counter from './Counter';
import Gateways from '../gateways';
import User from '../users';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard = (props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [gateways, setGateways] = useState(0);
  const [users, setUsers] = useState(0);
  const version = useVersion();

  const fetchGateways = useCallback(async () => {
    const { data } = await dataProvider.getList('gateways', {
      filter: {},
      sort: {},
      pagination: { page: 1, perPage: 100 },
    });
    const gateways = data.length || 0;
    setGateways(gateways);
  }, [dataProvider]);

  const fetchUsers = useCallback(async () => {
    const { data } = await dataProvider.getList('users', {
      filter: {},
      sort: {},
      pagination: { page: 1, perPage: 100 },
    });
    const users = data.length || 0;
    setUsers(users);
  }, [dataProvider]);

  useEffect(() => {
    fetchGateways();
    fetchUsers();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        <div style={styles.flex}>
          <Counter
            color='#12549A'
            value={gateways}
            icon={Gateways.icon}
            label={translate('res.gateways.name', gateways)}
          />
          <Counter
            color='#197a8a'
            value={users}
            icon={User.icon}
            label={translate('res.users.name', users)}
          />
        </div>
        <div style={styles.singleCol}>
          <Welcome />
        </div>
        <div style={styles.singleCol}></div>
      </div>
      <div style={styles.rightCol}>
        <div style={styles.flex}></div>
      </div>
    </div>
  );
};

export default Dashboard;
