import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, BooleanField } from 'react-admin';

import Check from '@material-ui/icons/Check';
import MailOutline from '@material-ui/icons/MailOutline';
import BlockOutlined from '@material-ui/icons/BlockOutlined';

const EmailNotVerified = () => (
  <MailOutline color="disabled" />
)

const DisabledIcon = () => (
  <BlockOutlined color="disabled" />
)

const UserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' sortable={false} label="ID" />
        <TextField source='email' sortable={false} />
        <BooleanField source='emailVerified' sortable={false} TrueIcon={MailOutline} FalseIcon={EmailNotVerified} />
        <BooleanField source='enabled' sortable={false} TrueIcon={Check} FalseIcon={DisabledIcon} />
        <TextField source='status' sortable={false} />
        <DateField
          source='updatedAt'
          label='Last update'
          locales='it-IT'
          showTime
          sortable={false}
        />
        <DateField
          source='createdAt'
          label='Created at'
          locales='it-IT'
          showTime
          sortable={false}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
