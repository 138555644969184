import List from './List';
import Show from './Show';
// import GatewayEdit from './GatewayEdit';

import GatewayIcon from '@material-ui/icons/DeveloperBoard';

const gateways = {
  list: List,
  show: Show,
  // edit: GatewayEdit,
  icon: GatewayIcon,
};


export default gateways;