import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CardIcon } from '../components';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
});

const Counter = ({ value, icon, label, color }) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <CardIcon Icon={icon} bgColor={color} htmlColor="primary" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {label}
                </Typography>
                <Typography variant="h5" component="h2">
                    {value}
                </Typography>
            </Card>
        </div>
    )
};

export default Counter;