import List from './List';
import Show from './Show';
// import Edit from './Edit';

import UserIcon from '@material-ui/icons/Person';

const users = {
  list: List,
  show: Show,
  // edit: Edit,
  icon: UserIcon,
};


export default users;