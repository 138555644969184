import Amplify, { Auth } from 'aws-amplify';
import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import { fetchUtils } from 'ra-core';
import {
  buildAuthProvider,
  buildDataProvider,
} from '@fedeonline/react-admin-amplify';
import restDataProvider from 'ra-data-json-server';
import compositeDataProvider from './dataProvider';
import awsExports from './aws-exports';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customRoutes from './customRoutes';
import gateways from './gateways';
import users from './users';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';

import { createMuiTheme } from '@material-ui/core/styles';

Amplify.configure(awsExports); // Configure Amplify the usual way

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'it') {
    return import('./i18n/it').then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

const httpClient = async (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  };
  // if (!options.headers) {
  //   options.headers = new Headers({ Accept: 'application/json' });
  // }
  // options.headers.set('Authorization', `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`);
  return fetchUtils.fetchJson(url, options);
};

const usersDataProvider = restDataProvider(
  'https://aimfdnatzf.execute-api.eu-west-1.amazonaws.com/dev',
  httpClient
);

const dataProvider = new compositeDataProvider([
  {
    dataProvider: buildDataProvider({ queries, mutations }),
    resources: ['gateways'],
  },
  {
    dataProvider: usersDataProvider,
    resources: ['users'],
  },
]);

const MyLoginPage = () => (
  <Login
    // A random image that changes everyday
    backgroundImage='https://source.unsplash.com/random/1600x900/?water'
  />
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#197a8a',
    },
    secondary: {
      main: '#12549A',
    },
  },
});

function App() {
  return (
    <Admin
      theme={theme}
      loginPage={MyLoginPage}
      authProvider={buildAuthProvider({ authGroups: ['Admins'] })}
      dashboard={Dashboard}
      customRoutes={customRoutes}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
      <Resource name='gateways' {...gateways} />
      <Resource name='users' {...users} />
      {/* Set the resources as you would do within Admin component */}
    </Admin>
  );
}

export default App;
