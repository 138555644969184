/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGateway = /* GraphQL */ `
  mutation CreateGateway(
    $input: CreateGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    createGateway(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateGateway = /* GraphQL */ `
  mutation UpdateGateway(
    $input: UpdateGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    updateGateway(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteGateway = /* GraphQL */ `
  mutation DeleteGateway(
    $input: DeleteGatewayInput!
    $condition: ModelGatewayConditionInput
  ) {
    deleteGateway(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
