import React from 'react';
import {
  DateField,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import Aside from './Aside';

const GatewayShow = (props) => {
  return (
    <Show aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label='res.gateways.fieldGroups.info'>
          <TextField source='id' label='ID' />
          <TextField source='name' />
          <TextField source='owner' />
          <DateField
            source='updatedAt'
            label='Last update'
            locales='it-IT'
            showTime
            sortable={false}
          />
          <DateField
            source='createdAt'
            label='Created at'
            locales='it-IT'
            showTime
            sortable={false}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default GatewayShow;
