import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers/AWSIotProvider';

import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
// window.LOG_LEVEL = 'DEBUG';

// Apply plugin with configuration
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-1',
    aws_pubsub_endpoint:
      'wss://a1nofw0w0zo2io-ats.iot.eu-west-1.amazonaws.com/mqtt',
  })
);

const useAsideStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 16,
    width: 600,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const options = {
  chart: {
    id: 'realtime',
    height: 350,
    type: 'line',
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 3000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    range: 10000,
    label: {
      format: 'HH:mm:ss',
    },
  },
  yaxis: {
    max: 50,
  },
  legend: {
    show: false,
  },
};

let data = [];

const updateChart = (message) => {
  const newValue = [message.value.time, message.value.temperature];
  data.push(newValue);
  ApexCharts.exec('realtime', 'updateSeries', [
    {
      data: data,
    },
  ]);
};

const Dashboard = () => {
  const classes = useAsideStyles();
  const translate = useTranslate();

  useEffect(() => {
    console.log('subscribing: ', PubSub.subscribe('myTopic'));

    PubSub.subscribe('myTopic', { provider: 'AWSIoTProvider' }).subscribe({
      next: (message) => updateChart(message),
      error: (error) => console.error('Error', error),
      close: () => console.log('Done'),
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      const newValue = {
        time: new Date().getTime(),
        temperature: Math.floor(Math.random() * 37) + 12,
      };
      PubSub.publish('myTopic', newValue);
    }, 1000);
  }, []);

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant='h6'>
            {translate('res.gateways.show.title')}
          </Typography>
          <Chart options={options} series={[{ data: [] }]} width='500' />
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
