/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://vmnfuuxj6jcmtntc5gdccgyp6e.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://aimfdnatzf.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:25f42c1a-3633-472b-8a22-789efa98692e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_vGTgqJQEk",
    "aws_user_pools_web_client_id": "34d0sc2pl2pmpcurn4lradu9sl",
    "oauth": {}
};


export default awsmobile;
