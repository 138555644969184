/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGateway = /* GraphQL */ `
  query GetGateway($id: ID!) {
    getGateway(id: $id) {
      id
      name
      description
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listGateways = /* GraphQL */ `
  query ListGateways(
    $filter: ModelGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gatewaysByName = /* GraphQL */ `
  query GatewaysByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatewaysByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
