import englishMessages from 'ra-language-english';

const english = {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      gateways: 'Gateways',
      users: 'Users',
      welcome: {
        title: 'Blupura IoT dashboard',
        subtitle: 'IoT dashboard for device management.',
      },
    },
  },
  res: {
    users: {
      name: 'User |||| Users',
      fields: {},
      fieldGroups: {},
      page: {
        delete: 'Delete User',
      },
      errors: {},
    },
    gateways: {
      name: 'Gateway |||| Gateways',
      label: 'Gateway',
      amount: '1 gateway |||| %{smart_count} gateways',
      fields: {
        temperature: 'Temperature',
        humidity: 'Humidity',
        flow: 'Water Flow',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
      },
      show: {
        title: 'Telemetries',
        subtitle: 'Current data...',
      },
      action: {
        accept: 'Accept',
      },
      fieldGroups: {
        info: 'Info',
      },
    },
    errors: {
      greaterThan: 'Must be greater than %{val}',
      lesserThan: 'Must be lesser than %{val}',
    },
  },
};

export default english;
