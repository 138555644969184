import React, { useEffect, useState, useRef } from 'react';
import { useTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers/AWSIotProvider';
import Temperature from '../components/Temperature';
import Humidity from '../components/Humidity';
import WaterFlow from '../components/WaterFlow';

import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

// Apply plugin with configuration
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-1',
    aws_pubsub_endpoint:
      'wss://a1nofw0w0zo2io-ats.iot.eu-west-1.amazonaws.com/mqtt',
  })
);

const useAsideStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 16,
    width: 600,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const chartOptions = {
  chart: {
    id: 'realtime',
    height: 350,
    type: 'line',
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 8000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    range: 100000,
    label: {
      format: 'HH:mm:ss',
    },
  },
  yaxis: {
    max: 35,
  },
  legend: {
    show: false,
  },
};

const Aside = () => {
  const classes = useAsideStyles();
  const translate = useTranslate();

  const [temp, setTemp] = useState(25);
  const [data, setData] = useState([[(new Date()).getTime(), 25]]);
  const [hum, setHum] = useState(50);
  const [flow, setFlow] = useState(2.0);
  const [msg, setMsg] = useState('');

  const tempRef = useRef(temp);
  tempRef.current = temp;

  const humRef = useRef(hum);
  humRef.current = hum;

  const flowRef = useRef(flow);
  flowRef.current = flow;

  const dataRef = useRef(data);
  dataRef.current = data;

  const publishRandomValues = () => {
    const incTemperature = Math.random() > 0.5;
    const incHumidity = Math.random() > 0.3;
    const incFlow = Math.random() > 0.5;

    const newTemp = incTemperature ? tempRef.current + 1 : tempRef.current - 1;
    const newHum = incHumidity ? humRef.current + 1 : humRef.current - 1;
    const newFlow = incFlow ? flowRef.current + 0.1 : flowRef.current - 0.1;
    const d = new Date();
    const msg = {
      time: d.toISOString(),
      timestamp: d.getTime(),
      temperature: newTemp,
      humidity: newHum > 98 ? 99 : newHum,
      waterFlow: newFlow,
    };
    PubSub.publish('blu', msg);
  };

  const updateChart = (message) => {
  const newValue = [message.value.timestamp, message.value.temperature];
  let newData = dataRef.current;
  newData.push(newValue);
  setData(newData);
  ApexCharts.exec('realtime', 'updateSeries', [
    {
      data: newData,
    },
  ]);
};


  useEffect(() => {
    // console.log('subscribing: ', PubSub.subscribe('blu'));

    PubSub.subscribe('blu', { provider: 'AWSIoTProvider' }).subscribe({
      next: (message) => {
        setTemp(message.value.temperature);
        setHum(message.value.humidity);
        setFlow(message.value.waterFlow);
        setMsg(JSON.stringify(message.value, null, 2));
        updateChart(message);
      },
      error: (error) => console.error('Error', error),
      close: () => console.log('Done'),
    });

    setInterval(() => {
      publishRandomValues();
    }, 5000);
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        justify='space-evenly'
        alignItems='stretch'
        spacing={5}
      >
        <Grid item>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='row'
                alignItems='start'
                alignContent='center'
              >
                <Box mx='auto'>
                  <Temperature value={temp} />
                </Box>
                <Box mx='auto'>
                  <Humidity value={hum} />
                </Box>
                <Box mx='auto'>
                  <WaterFlow value={flow} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
            <Typography variant='h6' className={classes.value}>{translate('res.gateways.fields.temperature')}</Typography>
              <Chart
                options={chartOptions}
                series={[{ data: data.slice() }]}
                width='500'
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <pre>{msg}</pre>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Aside;
