import React from 'react';
import { List, Datagrid, TextField, DateField, TextInput, Filter } from 'react-admin';

import { Pagination } from '../components';
// import { AmplifyFilter } from "react-admin-amplify";

// const GatewayFilter = (props) => (
//   <AmplifyFilter {...props}>
//     <TextInput source='gatewaysByName.name' label='Name' alwaysOn resettable />
//     <TextInput source='gatewaysByName.id.beginsWith' label='ID' alwaysOn resettable />
//   </AmplifyFilter>
// );

const GatewayFilter = props => (
    <Filter {...props} >
        <TextInput source="listGateways.filter.id.contains" label="ID" alwaysOn />
        <TextInput source="listGateways.filter.name.contains" label="Name" alwaysOn />
        <TextInput source="listGateways.filter.owner.contains" label="Owner" alwaysOn />
    </Filter>
);

const GatewayList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<GatewayFilter />}
      pagination={<Pagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' sortable={false} />
        <TextField source='name' sortable={false} />
        <TextField source='description' sortable={false} />
        <TextField source='owner' sortable={false} />
        <DateField
          source='updatedAt'
          label='Last update'
          locales='it-IT'
          showTime
          sortable={false}
        />
        <DateField
          source='createdAt'
          label='Created at'
          locales='it-IT'
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default GatewayList;
